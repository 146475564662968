import { motion } from 'framer-motion'
import { lazy, Suspense } from 'react'
import styled, {  ThemeProvider } from 'styled-components'

import { DarkTheme, mediaQueries } from './Themes'
import Loading from '../subComponents/Loading';


//Thumbnails
import { MusicHallData } from './MusicHallData';
// import MusicHallCard from '../subComponents/MusicHallCard';

const MusicHallCard = lazy(() => import("../subComponents/MusicHallCard"));

// import img1 from "../assets/Music-Hall/1.jpg";

// import img2 from "../assets/Music-Hall/2.jpg";
// import img3 from "../assets/Music-Hall/3.jpg";
// import img4 from "../assets/Music-Hall/4.jpg";
// import img5 from "../assets/Music-Hall/5.jpg";
// import img6 from "../assets/Music-Hall/6.jpg";
// import img7 from "../assets/Music-Hall/7.jpg";
// import img8 from "../assets/Music-Hall/8.jpg";
// import img9 from "../assets/Music-Hall/9.jpg";
// import img10 from "../assets/Music-Hall/10.jpg";
// import img11 from "../assets/Music-Hall/11.jpg";
// import img12 from "../assets/Music-Hall/12.jpg";
// import img13 from "../assets/Music-Hall/13.jpg";
// import img14 from "../assets/Music-Hall/14.jpg";
// import img15 from "../assets/Music-Hall/15.jpg";
// import img16 from "../assets/Music-Hall/16.jpg";
// import img17 from "../assets/Music-Hall/17.jpg";
// import img18 from "../assets/Music-Hall/18.jpg";
// import img19 from "../assets/Music-Hall/19.jpg";
// import img20 from "../assets/Music-Hall/20.jpg";
// import img21 from "../assets/Music-Hall/21.jpg";
// import img22 from "../assets/Music-Hall/22.jpg";
// import img23 from "../assets/Music-Hall/23.jpg";
// import img24 from "../assets/Music-Hall/24.jpg";
// import img25 from "../assets/Music-Hall/25.jpg";
// import img26 from "../assets/Music-Hall/26.jpg";
// import img27 from "../assets/Music-Hall/27.jpg";
// import img28 from "../assets/Music-Hall/28.jpg";
// import img29 from "../assets/Music-Hall/29.jpg";
// import img30 from "../assets/Music-Hall/30.jpg";



//Components
const SocialIcons = lazy(() => import('../subComponents/SocialIcons'))
const PowerButton = lazy(() => import('../subComponents/PowerButton'))
const LogoComponent = lazy(() => import('../subComponents/LogoComponent'))
const ParticlesComponent = lazy(() =>
  import('../subComponents/ParticlesComponent')
)
const BigTitle = lazy(() => import('../subComponents/BigTitle'))


const Box = styled(motion.div)`
  background-color: ${(props) => props.theme.body};
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
`




const Main = styled(motion.div)`
  border: 2px solid ${(props) => props.theme.text};
  color: ${(props) => props.theme.text};
  padding: 2rem;
  width: 80vw;
  height: 70vh;
  z-index: 3;
  /* line-height: 1.5; */
  
  font-size: calc(0.6rem + 1vw);
 backdrop-filter: blur(4px);

 margin-top: 4rem;
  
 

  font-family: 'Ubuntu Mono', monospace;
  font-style: italic;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 4rem;
  padding: 4rem;
  justify-items: center;
  align-items: center;
  place-items: center;
  overflow-y: scroll;

  
  &::-webkit-scrollbar {
  width: 0.5em;
}
 
&::-webkit-scrollbar-track {

}
 
&::-webkit-scrollbar-thumb {
  background-color: ${props => props.theme.text};
  padding-right: 2px;
}

${mediaQueries(80)`
   gap: 3rem;
  padding: 3rem;

  `};

${mediaQueries(70)`
     grid-template-columns: 1fr 1fr;
     padding: 2rem;

  `};
  ${mediaQueries(48)`
     grid-template-columns: 1fr;

  `};

${mediaQueries(64)`
          width: 70vw;
               



  `};

  ${mediaQueries(40)`
          width: 60vw;
 margin-top: 3rem;
               



  `};
  ${mediaQueries(30)`
          width: 60vw;
          

  `};



`

const MusicHall = () => {
  return (
    <ThemeProvider theme={DarkTheme}>
      <Suspense fallback={<Loading/>}>
        <Box
          key='skills'
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.5 } }}
          exit={{ opacity: 0, transition: { duration: 0.5 } }}>
          <LogoComponent theme='dark' />
          <PowerButton />
          <SocialIcons theme='dark' />
          <ParticlesComponent theme='dark' />

         
          <Main
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 1, delay: 1 } }}>
          
           <Suspense fallback={null}>
           {
            MusicHallData.map(m => 
            <MusicHallCard key={m.id} text={m.text} imgSrc={m.imgLink} link={m.youtubeLink}  />
            )
           }
           </Suspense>
           
          </Main>
          <BigTitle text='MUSIC-HALL' top='10%' left='5%' />
        </Box>
      </Suspense>
    </ThemeProvider>
  )
}

export default MusicHall
