import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { lazy, Suspense } from "react";
import GlobalStyle from "./globalStyles";
import { ThemeProvider } from "styled-components";
import { lightTheme } from "./components/Themes";
import Loading from "./subComponents/Loading";
// import { ApiProvider } from "./context/ApiContext";
import MusicHall from './components/MusicHall';

//Components
const Main = lazy(() => import("./components/Main"));
const AboutPage = lazy(() => import("./components/AboutPage"));
const MySkillsPage = lazy(() => import("./components/MySkillsPage"));
const BlogPage = lazy(() => import("./components/BlogPage"));
const WorkPage = lazy(() => import("./components/WorkPage"));
const SoundBar = lazy(() => import("./subComponents/SoundBar"));

function App() {
  const location = useLocation();

  return (
    <>
      <GlobalStyle />

      <ThemeProvider theme={lightTheme}>
        <Suspense fallback={<Loading />}>
          <SoundBar />

          <AnimatePresence mode='wait'>
          {/* <ApiProvider> */}
            <Routes location={location} key={location.pathname}>
              <Route  path="/" element={<Main />} />

              <Route  path="/about" element={<AboutPage />} />
              <Route  path="/music-hall" element={<MusicHall />} />


              <Route  path="/gallery" element={<BlogPage />} />

              <Route  path="/work" element={<WorkPage />} />

              <Route  path="/acting" element={<MySkillsPage />} />

          <Route  path="*" element={<Main />} />
            </Routes>
            {/* </ApiProvider> */}
          </AnimatePresence>
        </Suspense>
      </ThemeProvider>
    </>
  );
}

export default App;
